// 文字列操作に関するutil
import { APP_MODE, CONSOLE_METHOD, TEXT_LENGTH } from "../resources/constants";

/**
 * 文字列を全角2バイト、半角1バイトとしてカウントする関数
 * @param {*} str カウントする文字列
 * @returns バイト数
 */
export const byteCount = (str) => {
  let len = 0;

  for (let i = 0; i < str?.length; i++) {
    str[i].match(/[ -~]/) ? (len += 1) : (len += 2);
  }

  return len;
};

export const urlToId = (url) => {
  if (url) {
    // urlをスラッシュで分割して配列にする
    const array = url.split("/");
    // 配列の最後がidのため、それを抽出する
    const id = array.slice(-1)[0];
    return id;
  }
};

export const consoleMethod = (item, method) => {
  if (process.env.REACT_APP_MODE === APP_MODE.DEVELOPMENT) {
    switch (method) {
      case CONSOLE_METHOD.LOG:
        console.log(item);
        break;
      case CONSOLE_METHOD.ERROR:
        console.error(item.code);
        console.error(item);
        break;
      default:
        break;
    }
  }
};

/**
 * オブジェクトをソート済み配列に変換する処理
 * @param {*} obj
 * @returns
 */
const objToSortedArray = (obj) => Object.entries(obj).sort();

/**
 * ソート済み配列を文字列に変換して比較する処理
 * @param {*} obj1
 * @param {*} obj2
 * @returns
 */
const isEqualOneDimentionalArray = (obj1, obj2) =>
  JSON.stringify(objToSortedArray(obj1)) ===
  JSON.stringify(objToSortedArray(obj2));

/**
 * 2つのオブジェクトが同じものかを比較する処理
 * 再帰処理を行い、ネストされたオブジェクトまで比較する
 * @param {*} obj1
 * @param {*} obj2
 * @returns
 */
export const isEqual = (obj1, obj2) =>
  isEqualOneDimentionalArray(obj1, obj2) &&
  objToSortedArray(obj1).map(([key, val]) =>
    typeof val === "object" ? isEqual(val, obj2[key]) : true
  );

/**
 * 文字列のエスケープ処理
 * @param {*} str
 * @returns
 */
export const escapeInput = (str) => {
  return str
    .replace(/\./g, "&#046;")
    .replace(/\[/g, "&#091;")
    .replace(/\]/g, "&#093;")
    .replace(/\*/g, "&#042;")
    .replace(/\\/g, "&#092;")
    .replace(/\//g, "&#047;")
    .replace(/~/g, "&#126;")
    .replace(/`/g, "&#096;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
};

/**
 * エスケープした文字をもとに戻す
 * @param {*} str
 * @returns
 */
export const unescapeString = (str) => {
  const unescaped = str
    ? str
        .replace("&#046;", ".")
        .replace("&#091;", "[")
        .replace("&#092;", "\\")
        .replace("&#093;", "]")
        .replace("&#042;", "*")
        .replace("&#047;", "/")
        .replace("&#126;", "~")
        .replace("&#096;", "`")
        .replace("&lt;", "<")
        .replace("&gt;", ">")
    : "";
  return unescaped;
};

/**
 * デバイスがタッチスクリーンを持つかどうか（スマホorタブレットかどうか）判定する処理
 * @returns
 */
export const hasTouchScreen = () => {
  let bool;
  if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
    bool = true;
  } else {
    bool = false;
  }
  return bool;
};

/**
 * エンターキーを押した際に決定アクションを実行するための処理
 * @param {*} key
 * @param {*} event
 */
export const onEnterKey = (key, action, param) => {
  if (key === "Enter") {
    action(param);
  }
};

export const urlWithTrackingId = (url) => {
  if (!url) {
    return;
  }
  const urlObject = new URL(url);
  // todo シノペ タグを削除するのでメソッド名を変える
  urlObject.searchParams.delete("tag");
  return urlObject.toString();
};

export function tanaNameValidator(tanaName){
  return tanaName.length <= TEXT_LENGTH.TAB_NAME && tanaName.length >= TEXT_LENGTH.MIN;
}
