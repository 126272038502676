import React, { useState } from "react";
import { usePreview } from "react-dnd-preview";

const DndPreview = ({ src, isEdit }) => {
  const { display, style } = usePreview();
  const [image] = useState(src);

  if (!display || !isEdit || !image) {
    return null;
  }
  return (
    <img
      src={image}
      alt=""
      style={{ ...style, width: "100px", height: "100px" }}
    />
  );
};

export default DndPreview;
