import React, { useCallback, useState, useEffect } from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import {
  ALPHANUMERIC_REGEX,
  DIALOG_MODE,
  SNS_LINK,
  TABLE_NAME,
  TEXT_LENGTH,
} from "../../resources/constants";
import {
  BUTTON_TEXT,
  DIALOG_TITLE,
  ERROR_MESSAGES,
  PLACEHOLDER_TEXT,
  NOTES,
} from "../../resources/messages";
import commonStyles from "../../styles/common.module.scss";
import facebookIcon from "../../resources/images/f_logo_RGB-Blue_100.png";
import instagramIcon from "../../resources/images/Instagram_Glyph_Gradient_RGB.png";
import pinterestIcon from "../../resources/images/Pinterest-badge-red-RGB.svg";
import twitterIcon from "../../resources/images/Twitter_circle_blue.svg";
import { useParams } from "react-router-dom";
import { urlToId, onEnterKey } from "../../utils/commonUtil";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebase";

// 各種ダイアログは、役割ごとにまとめる（ユーザ設定に関するダイアログ、Confirmダイアログ、アラートダイアログなど）
// このファイルをダイアログコンポーネントの見本にしたい
const UserSettingDialog = ({
  userInfo,
  dialogMode,
  isOpen,
  handleClose,
  setUserInfo,
  setIsLoading,
}) => {
  const { userId } = useParams();
  const [message, setMessage] = useState("");
  const [twitterInput, setTwitterInput] = useState("");
  const [facebookInput, setFacebookInput] = useState("");
  const [instagramInput, setInstagramInput] = useState("");
  const [pinterestInput, setPinterestInput] = useState("");
  const [userName, setUserName] = useState("");
  const [hasSnsInput, setHasSnsInput] = useState(false);
  const [hasUserName, setHasUserName] = useState(false);

  const handleUpdateLink = () => {
    // todo シノペ ここのネストを整理する
    const isValidId = validateId();
    if (isValidId) {
      const hasChangeInput = checkChangeInput();
      if (!hasChangeInput) {
        handleClose();
      } else {
        setIsLoading(true);
        handleClose();

        const twitterLink = twitterInput ? SNS_LINK.TWITTER + twitterInput : "";
        const facebookLink = facebookInput
          ? SNS_LINK.FACEBOOK + facebookInput
          : "";
        const instagramLink = instagramInput
          ? SNS_LINK.INSTAGRAM + instagramInput
          : "";
        const pinterestLink = pinterestInput
          ? SNS_LINK.PINTEREST + pinterestInput
          : "";

        const userDocumentRef = doc(db, TABLE_NAME.USERS, userId);
        updateDoc(userDocumentRef, {
          twitter_link: twitterLink,
          facebook_link: facebookLink,
          instagram_link: instagramLink,
          pinterest_link: pinterestLink,
        });

        setUserInfo((userInfo) => ({
          ...userInfo,
          twitter_link: twitterLink,
          facebook_link: facebookLink,
          instagram_link: instagramLink,
          pinterest_link: pinterestLink,
        }));
      }
    }
    setIsLoading(false);
  };

  const handleUpdateName = () => {
    if (userName.length > TEXT_LENGTH.NAME) {
      alert(ERROR_MESSAGES.COUNT_OVER_NAME_LENGTH);
    } else if (!userName) {
      alert(ERROR_MESSAGES.NO_INPUT);
    } else if (userInfo.name === userName) {
      handleClose();
    } else {
      setIsLoading(true);
      handleClose();
      const userDocumentRef = doc(db, TABLE_NAME.USERS, userId);
      updateDoc(userDocumentRef, {
        name: userName,
      });

      setUserInfo((userInfo) => ({
        ...userInfo,
        name: userName,
      }));
      setIsLoading(false);
    }
  };

  const handleCloseName = () => {
    if (hasUserName) {
      handleClose();
    }
  };

  const validateId = () => {
    if (
      (twitterInput.match(ALPHANUMERIC_REGEX) || !twitterInput) &&
      (facebookInput.match(ALPHANUMERIC_REGEX) || !facebookInput) &&
      (instagramInput.match(ALPHANUMERIC_REGEX) || !instagramInput) &&
      (pinterestInput.match(ALPHANUMERIC_REGEX) || !pinterestInput)
    ) {
      return true;
    } else {
      alert(ERROR_MESSAGES.IRREGULAR_ID);
      return false;
    }
  };

  const checkChangeInput = () => {
    const before = [
      urlToId(userInfo.twitterLink) || "",
      urlToId(userInfo.facebookLink) || "",
      urlToId(userInfo.instagramLink) || "",
      urlToId(userInfo.pinterestLink) || "",
    ];
    const after = [twitterInput, facebookInput, instagramInput, pinterestInput];
    const result = JSON.stringify(before) !== JSON.stringify(after);
    return result;
  };

  const setInitSNSLink = useCallback(() => {
    // 各IDが登録されてない状態でそのままstateを更新するとundefinedで更新されて警告が出るため、リンクがなければ空文字で登録する
    setTwitterInput(urlToId(userInfo.twitterLink) || "");
    setFacebookInput(urlToId(userInfo.facebookLink) || "");
    setInstagramInput(urlToId(userInfo.instagramLink) || "");
    setPinterestInput(urlToId(userInfo.pinterestLink) || "");
  }, [userInfo]);

  const setInitName = useCallback(() => {
    setUserName(userInfo.name || "");
    setHasUserName(userInfo.name ? true : false);
  }, [userInfo]);

  useEffect(() => {
    switch (dialogMode) {
      case DIALOG_MODE.SETTING_SNS:
        setMessage(DIALOG_TITLE.SETTING_SNS);
        setInitSNSLink();
        setHasSnsInput(true);
        break;
      case DIALOG_MODE.SETTING_NAME:
        setMessage(DIALOG_TITLE.SETTING_USER_NAME);
        setInitName();
        setHasSnsInput(false);
        break;
      default:
        break;
    }
  }, [dialogMode, setInitSNSLink, setInitName]);

  return (
    <Dialog
      className={commonStyles.modalDialog}
      open={isOpen}
      onClose={handleCloseName}
      // ダイアログのボックス（白いハコ）のスタイルを上書きするためこのように書いている
      PaperProps={{
        className: commonStyles.dialogPaper,
      }}
    >
      <DialogTitle className={commonStyles.dialogTitle}>{message}</DialogTitle>
      <DialogContent className={commonStyles.dialogText}>
        {hasSnsInput ? (
          <div>
            <SnsInput
              icon={twitterIcon}
              snsLink={twitterInput}
              changeAction={(event) => setTwitterInput(event.target.value)}
            />
            <SnsInput
              icon={facebookIcon}
              snsLink={facebookInput}
              changeAction={(event) => setFacebookInput(event.target.value)}
            />
            <SnsInput
              icon={instagramIcon}
              snsLink={instagramInput}
              changeAction={(event) => setInstagramInput(event.target.value)}
            />
            <SnsInput
              icon={pinterestIcon}
              snsLink={pinterestInput}
              changeAction={(event) => setPinterestInput(event.target.value)}
            />
          </div>
        ) : (
          <input
            type="text"
            className={commonStyles.inputText}
            value={userName}
            onChange={(event) => setUserName(event.target.value)}
            onKeyDown={(event) =>
              onEnterKey(
                event.key,
                dialogMode === DIALOG_MODE.SETTING_NAME
                  ? handleUpdateName
                  : handleUpdateLink
              )
            }
          />
        )}
        {!hasSnsInput && (
          <div className={commonStyles.notes}>{NOTES.NAME_LIMIT}</div>
        )}
      </DialogContent>
      <DialogActions className={commonStyles.dialogActions}>
        <button
          className={commonStyles.actionButton}
          onClick={
            dialogMode === DIALOG_MODE.SETTING_NAME
              ? handleUpdateName
              : handleUpdateLink
          }
        >
          {BUTTON_TEXT.OK}
        </button>
      </DialogActions>
    </Dialog>
  );
};

// SNS入力欄
// コンポーネント分割の見本として定義
// 引数の波括弧内にあるものは、propsとして渡している変数
const SnsInput = ({ icon, snsLink, changeAction }) => {
  return (
    <div className={commonStyles.snsInput}>
      <img className={commonStyles.snsIcon} src={icon} alt="" />
      <input
        type="text"
        className={commonStyles.snsInputText}
        value={snsLink}
        onChange={changeAction}
        placeholder={PLACEHOLDER_TEXT.INPUT_SNS_ID}
      />
    </div>
  );
};

export default UserSettingDialog;
