import React, { useContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../config/firebase";

// 認証処理を管理するためのcontext
// Reactで認証情報を管理するための方法はいくつかある（例:Redux）が、今回は導入コスト（と学習コスト）を下げるためuseContextで認証処理を行う
// 実装にあたって下記リンクを参照した
// 基本的な実装方法: https://reffect.co.jp/react/react-firebase-auth#:~:text=%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%97%E3%81%9F%E3%80%82-,%E3%83%A6%E3%83%BC%E3%82%B6%E6%83%85%E5%A0%B1%E3%81%AE%E5%85%B1%E6%9C%89(Context%08),-%E3%83%A6%E3%83%BC%E3%82%B6%E7%99%BB%E9%8C%B2%E3%81%8C
// ↑のfirebase関連の書き方が古いため、Googleの最新ドキュメントを参照: https://firebase.google.com/docs/auth/web/manage-users?hl=ja
const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [authInfo, setAuthInfo] = useState("");

  const value = {
    authInfo,
  };

  useEffect(() => {
    const getUser = onAuthStateChanged(auth, (user) => {
      // userの値がある場合はuser.reloadUserInfoを代入し、userの値がなければnullを代入する
      setAuthInfo(user?.reloadUserInfo);
    });
    return () => {
      getUser();
    };
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export default AuthProvider;
