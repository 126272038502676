// AmazonのPA-APIは購入実績がないと使えないので、Amazonリンクをユーザに入力してもらって登録する
// https://noknow.info/it/javascript/get_url_from_text_string?lang=ja
// https://www.sejuku.net/blog/21049
// PA-APIが使えるようになったら下記を参考につくる
// https://blog.ver001.com/amazon_product_advertising_api/
// https://namu-r21.hatenablog.com/entry/2019/07/21/003700
import React, { useState, useEffect } from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import {
  DIALOG_MODE,
  AMAZON_IMAGE_LINK,
  ASIN_REGEX,
  TEXT_LENGTH,
  CONSOLE_METHOD,
  VIDEO_ASIN_REGEX,
  TABLE_NAME,
  EMPTY_CONTENT_LIST,
  MUSIC_ASIN_REGEX,
  API_URL_EXPAND,
  API_URL_EXPAND_QUERY,
} from "../../resources/constants";
import {
  BUTTON_TEXT,
  DIALOG_TITLE,
  ERROR_MESSAGES,
  PLACEHOLDER_TEXT,
  NOTES,
} from "../../resources/messages";
import commonStyles from "../../styles/common.module.scss";
import {
  consoleMethod,
  escapeInput,
  onEnterKey,
  unescapeString,
  tanaNameValidator,
} from "../../utils/commonUtil";
import { db } from "../../config/firebase";
import { doc, updateDoc } from "firebase/firestore";
// import searchAmazon from "../../modules/amazonSearch";
// import ProgressRing from "./ProgressRing";

// todo シノペ propsの中身わかりにくい問題に対処する（優先度低）
const AddContentDialog = (props) => {
  const [title, setTitle] = useState("");
  const [placeholderText, setPlaceholderText] = useState("");
  const [isAddContent, setIsAddContent] = useState(false);
  const [value, setValue] = useState("");
  const [isValidated, setIsValidated] = useState(false);
  // 検索機能周りのstateを非活性化
  // const [searchCategory, setSearchCategory] = useState("");
  // const [searchText, setSearchText] = useState("");
  // const [searchResults, setSearchResults] = useState([]);
  // const [resultsCount, setResultsCount] = useState(9);
  // const [isLoading, setIsLoading] = useState(false);
  const [amazonUrl, setAmazonUrl] = useState("");

  // const searchCategories = [
  //   {
  //     key: "",
  //     value: "すべて",
  //   },
  //   {
  //     key: "digital-text",
  //     value: "Kindleストア",
  //   },
  //   {
  //     key: "instant-video",
  //     value: "Prime Video",
  //   },
  //   {
  //     key: "digital-music",
  //     value: "デジタルミュージック",
  //   },
  //   {
  //     key: "stripbooks",
  //     value: "本",
  //   },
  //   {
  //     key: "popular",
  //     value: "ミュージック",
  //   },
  //   {
  //     key: "videogames",
  //     value: "TVゲーム",
  //   },
  //   {
  //     key: "software",
  //     value: "PCソフト",
  //   },
  //   {
  //     key: "computers",
  //     value: "パソコン・周辺機器",
  //   },
  //   {
  //     key: "food-beverage",
  //     value: "食品・飲料",
  //   },
  //   {
  //     key: "electronics",
  //     value: "家電・カメラ",
  //   },
  //   {
  //     key: "office-products",
  //     value: "文房具・オフィス用品",
  //   },
  //   { key: "hobby", value: "ホビー" },
  //   { key: "toys", value: "おもちゃ" },
  //   { key: "diy", value: "DIY・工具" },
  // ];

  const handleChange = (event) => {
    setValue(event.target.value);
    setIsValidated(tanaNameValidator(event.target.value));
  };

  const handleClose = () => {
    setValue("");
    // setSearchResults([]);
    props.handleClose();
  };

  const handleAddTab = async () => {
    try {
      if (
        unescapeString(value).length > TEXT_LENGTH.TAB_NAME ||
        unescapeString(value).length < TEXT_LENGTH.MIN
      ) {
        alert(ERROR_MESSAGES.COUNT_OVER_TITLE_LENGTH);
      } else if (props.tabList.includes(value)) {
        alert(ERROR_MESSAGES.CATEGORY_ALREADY_EXISTS);
      } else {
        handleClose();
        props.setIsLoading(true);
        // タブを追加する前にコンテンツの変更があった場合、更新しておく
        props.handleSubmitEdit();
        const contentsDocumentRef = doc(db, TABLE_NAME.CONTENTS, props.userId);
        // コンテンツの最大数（9個分）の空データを予めDBに登録しておく
        // [value]で、TANA名をオブジェクトのキーとして渡している
        await updateDoc(contentsDocumentRef, {
          [escapeInput(value)]: {
            created_at: new Date(),
            content_list: EMPTY_CONTENT_LIST,
          },
        });
        props.setTabNameInput(value);
        props.handleAddTab();
        await props.getListData();
        setIsValidated(false);
      }
    } catch (error) {
      // todo シノペ エラーメッセージをこのダイアログに表示する
      consoleMethod(error, CONSOLE_METHOD.ERROR);
      alert(ERROR_MESSAGES.SYSTEM_ERROR);
    }
  };

  // 検索機能をオミット
  // const handleUpdateSearchResults = () => {
  //   const searchCount = resultsCount + 9;
  //   setResultsCount(searchCount);
  //   handleSearch(searchCount);
  // };

  const handleUpdateContent = async (productUrl) => {
    // リンクによってはスラッシュがエンコードされているため、デコードする
    const decodedProductUrl = productUrl.replace("dp%2F", "dp/");

    const getAsin = (url) => {
      // AmazonのリンクからASIN（ISBN）を抜き出す三項演算子
      // 1.入力値に"dp/XXXXXXXXXX"という文字列があるか確認する
      // 2."dp/XXXXXXXXXX"があれば、そこから"dp/"を削除してASINのみ抽出し、asinに代入する
      // 3."dp/XXXXXXXXXX"がなければ、今度は入力値に"gp/video/detail/XXXXXXXXXX"があるか確認する
      // 4."gp/video/detail/XXXXXXXXXX"があればASINのみ抽出し、なければnullを代入する
      return url.match(ASIN_REGEX)
      ? url.match(ASIN_REGEX)[0].replace("dp/", "")
      : url.match(VIDEO_ASIN_REGEX)
      ? url
          .match(VIDEO_ASIN_REGEX)[0]
          .replace("gp/video/detail/", "")
      : url.match(MUSIC_ASIN_REGEX)
      ? url
          .match(MUSIC_ASIN_REGEX)[0]
          .replace("music/player/albums/", "")
      : null;
    }

    let asin = getAsin(decodedProductUrl)
    if (!asin) {
      // もしASINが取れない場合は短縮URLの可能性もあるので、URLの展開処理を行ってから再度ASINを取ってみる
      const expandUrl = await (await fetch(`${API_URL_EXPAND}?${API_URL_EXPAND_QUERY}=${decodedProductUrl}`)).json()
      asin = getAsin(expandUrl.expand)
    }

    if (!asin) {
      // asinがnullなら非対応のリンクのため、アラートを出す
      // todo シノペ エラーの理由をアラートに出す
      alert(ERROR_MESSAGES.UNSUPPORTED_LINK);
    } else {
      handleClose();
      props.setIsLoading(true);
      const contentLink = `https://www.amazon.co.jp/dp/${asin}`;
      const contentLinkUrl = new URL(contentLink);
      const imageLinkUrl = new URL(AMAZON_IMAGE_LINK);
      // 画像リンクにASINを付与
      imageLinkUrl.searchParams.set("ASIN", asin);
      // コンテンツリストのstateを更新
      props.handleChangeContentsData(
        contentLinkUrl.toString(),
        imageLinkUrl.toString()
      );
      // setSearchResults([]);
      props.setIsLoading(false);
    }
  };

  // 検索機能をオミット
  // const handleSearch = (searchCount) => {
  //   setIsLoading(true);
  //   setTimeout(1000);
  //   setResultsCount(searchCount);
  //   searchAmazon(searchText, searchCategory)
  //     .then((data) => {
  //       setSearchResults(data.searchResults.slice(0, searchCount));
  //       if (data.searchResults.length === 0) {
  //         alert(ERROR_MESSAGES.NO_RESULTS);
  //       }
  //     })
  //     .catch((error) => consoleMethod(error, "error"));
  //   setIsLoading(false);
  // };

  useEffect(() => {
    switch (props.addDialogMode) {
      case DIALOG_MODE.ADD_CONTENT:
        setTitle(DIALOG_TITLE.ADD_CONTENT);
        setPlaceholderText(PLACEHOLDER_TEXT.INPUT_AMAZON_LINK);
        setIsAddContent(true);
        break;
      case DIALOG_MODE.ADD_CONTENT_LIST:
        setTitle(DIALOG_TITLE.SETTING_CONTENT_LIST_NAME);
        setPlaceholderText(PLACEHOLDER_TEXT.CONTENT_LIST_NAME_SAMPLE);
        setIsAddContent(false);
        break;
      default:
        break;
    }
  }, [props.addDialogMode]);

  return (
    <Dialog
      className={commonStyles.modalDialog}
      open={props.isOpen}
      onClose={handleClose}
      // ダイアログのボックス（白いハコ）のスタイルを上書きするためこのように書いている
      PaperProps={{
        className: props.isStandardWidth
          ? commonStyles.dialogPaper
          : commonStyles.dialogPaperMobile,
      }}
    >
      <DialogTitle className={commonStyles.dialogTitle}>{title}</DialogTitle>
      <DialogContent className={commonStyles.dialogContent}>
        {isAddContent ? (
          <>
            <div className={commonStyles.searchBar}>
              {/* 検索機能をオミット */}
              {/* {props.isStandardWidth && (
                <select
                  className={commonStyles.searchCategories}
                  size="1"
                  value={searchCategory}
                  onChange={(event) => setSearchCategory(event.target.value)}
                >
                  {searchCategories.map((category, idx) => {
                    return (
                      <option key={idx} value={category.key}>
                        {category.value}
                      </option>
                    );
                  })}
                </select>
              )}
              {!props.isStandardWidth && (
                <select
                  className={commonStyles.searchCategoriesMoblie}
                  size="1"
                  value={searchCategory}
                  onChange={(event) => setSearchCategory(event.target.value)}
                >
                  {searchCategories.map((category, idx) => {
                    return (
                      <option key={idx} value={category.key}>
                        {category.value}
                      </option>
                    );
                  })}
                </select>
              )}
              <input
                type="text"
                placeholder={PLACEHOLDER_TEXT.INPUT_SEARCH_WORD}
                className={commonStyles.searchInputText}
                onChange={(event) => setSearchText(event.target.value)}
                onKeyDown={(event) => onEnterKey(event.key, handleSearch)}
              />
              <button
                className={commonStyles.searchButton}
                onClick={() => handleSearch(9)}
              >
                {BUTTON_TEXT.SEARCH}
              </button>
              <div className={commonStyles.addContentNotes}>
                {props.isStandardWidth ? NOTES.SEARCH_NOTE : NOTES.SEARCH_NOTE_NEWLINE}
              </div> */}
              <input
                type="text"
                placeholder={PLACEHOLDER_TEXT.INPUT_AMAZON_LINK}
                className={
                  props.isStandardWidth
                    ? commonStyles.urlInputText
                    : commonStyles.urlInputTextMobile
                }
                onChange={(event) => setAmazonUrl(event.target.value)}
                onKeyDown={(event) =>
                  onEnterKey(event.key, handleUpdateContent, amazonUrl)
                }
              />
              <button
                className={commonStyles.searchButton}
                onClick={() => handleUpdateContent(amazonUrl)}
              >
                {BUTTON_TEXT.ADD}
              </button>
            </div>
            {/* 検索機能をオミット */}
            {/* <ul
              className={
                props.isStandardWidth
                  ? commonStyles.searchResults
                  : commonStyles.searchResultsMobile
              }
            >
              {searchResults.map((item, index) => {
                return (
                  <li key={index}>
                    <input
                      type="image"
                      src={item.imageUrl}
                      alt=""
                      className={commonStyles.searchResultsImage}
                      onClick={() => {
                        handleUpdateContent(item.productUrl);
                      }}
                    />
                  </li>
                );
              })}
            </ul> */}
          </>
        ) : (
          <input
            className={commonStyles.inputText}
            value={value}
            placeholder={placeholderText}
            onChange={handleChange}
          ></input>
        )}
        {!isAddContent && (
          <div className={commonStyles.notes}>{NOTES.TITLE_LIMIT}</div>
        )}
      </DialogContent>
      {!isAddContent && (
        <DialogActions style={{ justifyContent: "center" }}>
          <button
            className={isValidated ? commonStyles.actionButtonBlack : commonStyles.actionButtonBlackDisabled}
            onClick={handleAddTab}
            disabled={!isValidated}
          >
            {isAddContent ? BUTTON_TEXT.ADD : BUTTON_TEXT.DECIDE}
          </button>
        </DialogActions>
      )}
      {/* 検索機能をオミット */}
      {/* {isAddContent && searchResults.length > 0 && (
        <DialogActions style={{ justifyContent: "center" }}>
          <button
            className={commonStyles.actionButtonBlack}
            onClick={handleUpdateSearchResults}
          >
            {BUTTON_TEXT.SEARCH_MORE}
          </button>
        </DialogActions>
      )} */}
      {/* <ProgressRing isLoading={isLoading} /> */}
    </Dialog>
  );
};
export default AddContentDialog;
