// ログイン後のリダイレクト先として使用するページ。
// FirebaseのsignInWithRedirectの仕様でログイン後は元のページにリダイレクトするようになっているが、
// そのままだとログインページにリダイレクトしてしまい一瞬ログインに成功したかどうかがわからなくなる。
// そのため、ログイン時にこちらのページに遷移しておき、ログイン成功後にユーザページに飛ばす

import React, { useCallback, useEffect } from "react";
import ProgressRing from "../parts/ProgressRing";
import { useNavigate } from "react-router-dom";
import { getRedirectResult, updateProfile } from "firebase/auth";
import {
  CONSOLE_METHOD,
  DEFAULT_MAX_TAB_NUM,
  SNS_LINK,
  TABLE_NAME,
} from "../../resources/constants";
import { ERROR_MESSAGES } from "../../resources/messages";
import { useParams } from "react-router-dom";
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../config/firebase";
import { consoleMethod } from "../../utils/commonUtil";

const Callback = () => {
  // const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  // ログインと新規登録を分けていた名残でmodeを残している
  // ログイン以外でCallback.jsを使う場合はこれに応じて処理を分ける
  const { mode } = useParams();

  const handleError = useCallback(
    (error) => {
      // setErrorMessage(error.message);
      alert(error.message);
      consoleMethod(error, "error");
      navigate("/");
    },
    [navigate]
  );

  useEffect(() => {
    getRedirectResult(auth)
      .then(async (result) => {
        // 現状はTwitterログインのみだが、ログイン方法が変わった場合には修正する
        const userId = result ? result.user.reloadUserInfo.screenName : "";
        if (userId) {
          const userDocumentRef = doc(db, TABLE_NAME.USERS, userId);
          const contentsDocumentRef = doc(db, TABLE_NAME.CONTENTS, userId);
          await getDoc(userDocumentRef)
            .then((documentSnapshot) => {
              if (!documentSnapshot.data()) {
                setDoc(userDocumentRef, {
                  uid: result.user.uid,
                  name: result.user.displayName,
                  avatar: result.user.photoURL.replace("normal", "200x200"),
                  max_tab_num: DEFAULT_MAX_TAB_NUM,
                  twitter_link: SNS_LINK.TWITTER + userId,
                });
              } else {
                const avatar =
                  auth.currentUser.providerData[0].photoURL.replace(
                    "normal",
                    "200x200"
                  );
                // Twitterのアイコンが変わっていたら、SUKIDANAアプリのアイコンも変える
                // 画像変更機能が実装されることになったら、Twitterアイコンを使用している人だけここを通るように修正する
                if (avatar !== result.user.photoURL) {
                  // Firebase AuthenticationのphotoURLを更新する
                  updateProfile(auth.currentUser, { photoURL: avatar });
                  // DBのユーザ情報を更新する
                  updateDoc(userDocumentRef, {
                    avatar: avatar,
                  });
                }
              }
            })
            .catch((error) => consoleMethod(error, CONSOLE_METHOD.ERROR));
          await getDoc(contentsDocumentRef)
            .then((documentSnapshot) => {
              if (!documentSnapshot.data()) {
                setDoc(contentsDocumentRef, {});
              }
            })
            .catch((error) => consoleMethod(error, CONSOLE_METHOD.ERROR));
          navigate(`/${userId}/1`);
        }
      })
      .catch((error) => {
        // ユーザがTwitter連携をキャンセルしたときのエラー
        // エラーとしては扱わず、ログイン画面に戻す
        if (error.code === "auth/user-cancelled") {
          navigate("/");
          // 上記以外のエラーはシステムエラー（FirebaseとかTwitterとかが落ちていて、こちらではどうしようもないもの）として扱う
        } else {
          consoleMethod(error, CONSOLE_METHOD.ERROR);
          error.message = ERROR_MESSAGES.SYSTEM_ERROR;
          handleError(error);
        }
      });
  }, [navigate, handleError, mode]);

  return <ProgressRing isLoading={true} />;
};

export default Callback;
